import { useSearchParams } from "react-router-dom";
import Heading from "../shared/heading.landing";
import { useContext, useEffect, useState } from "react";
import useAsset from "../../hooks/useAsset";
import { UserContext } from "../../contexts/user.context";
import { Spinner } from "@material-tailwind/react";



const CoveredDevicesContent = ({ assets = [], isLoading, searchOptionsState = [], }) => {
    const [searchOptions, setSearchOptions] = searchOptionsState;
    const { search } = searchOptionsState?.length ? searchOptions : "";
    const [timedSearch, setTimedSearch] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const currentSearch = searchParams.get("search") || "";
    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setTimedSearch(search);
        }, 700);
    
        return () => {
          clearTimeout(timeoutId);
        };
      }, [search]);
      useEffect(() => {
        if (currentSearch === timedSearch) return;
        if (timedSearch === "") {
          searchParams.delete("search");
        } else {
          searchParams.set("search", timedSearch);
        }
        setSearchParams(searchParams);
      }, [timedSearch]);
    
      const handleChange = (e) => {
        //console.log(searchOptions);
        //console.log(e.target.name);
        setSearchOptions({ ...searchOptions, [e.target.name]: e.target.value });
      };
    const cellClass = `w-[35%] md:w-[15%] overflow-x-clip content-center`;
    return (
        <>
            <Heading text={'Covered Devices'} />
            <div className=" flex flex-col gap-3">
                <div className="flex flex-col border-2 border-solid p-4 rounded-2xl">
                    <div className="font-bold text-lg">Search</div>
                    <input 
                        type="search"
                        placeholder={`${currentSearch ? currentSearch : "Search..."}`}
                        autoComplete="off"
                        name="search"
                        value={search}
                        onChange={handleChange}
                        className="h-8 w-full md:w-1/2 bg-evstarLandingInputField rounded-lg" 
                    />
                </div>

                <div className="w-full overflow-x-auto">
                    <div className="flex flex-row font-bold p-2 w-[210%] md:w-full gap-1">
                        <div className={cellClass}>Serial Number</div>
                        <div className={cellClass}>Customer</div>
                        <div className={cellClass}>Device Make</div>
                        <div className={cellClass}>Device Model</div>
                        <div className={cellClass}>Coverage Start Date</div>
                        <div className={cellClass}>Coverage End Date</div>
                    </div>
                    
                    <div><hr className={`border-[1px] border-black rounded-3xl w-[210%] md:w-full`} /></div>
                    {
                        isLoading ?
                        <div className="flex flex-row gap-4">
                            <div>Fetching Devices...</div>
                            <Spinner color="light-green" className="h-8 w-8" />
                        </div>
                        :
                        <div className="">
                        {
                            assets.map((a, i, arr) => {
                                return (
                                    <>
                                        <div key={`${a._id}${arr.length}${i}`} className="flex flex-row font-bold text-gray-600 p-2 w-[210%] md:w-full gap-2 cursor-pointer hover:bg-gray-100">
                                            <div className={cellClass}>{a.asset_serial}</div>
                                            <div className={cellClass}>{a.customer_business_name}</div>
                                            <div className={cellClass}>{a.make}</div>
                                            <div className={cellClass}>{a.model}</div>
                                            <div className={cellClass}>{a.properties['Warranty Start Date']}</div>
                                            <div className={cellClass}>{a.properties['Warranty End Date']}</div>
                                        </div>
                                        <div><hr className={`border-[1px] border-gray-200 rounded-3xl w-[210%] md:w-full`} /></div>
                                    </>
                                );
                            })
                        }
                    </div>}
                </div>
            </div>
        </>
    );
}

export default CoveredDevicesContent;