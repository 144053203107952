import { useContext, useEffect, useRef, useState } from "react";
import OutLinedButton from "../buttons/outlined.button.component";
import Heading from "../shared/heading.landing";
import AutoSuggestInput from "autosuggest-input-box";
import { FETCH_CUSTOMERS_QUERY } from "../../api/queries/customer.queries";
import { UserContext } from "../../contexts/user.context";
import { useMutation, useQuery } from "@apollo/client";
import useAsset from "../../hooks/useAsset";
import { CREATE_TICKET_MUTATION } from "../../api/mutations/ticket.mutations";
import { CustomerContext } from "../../contexts/customer.context";
import { Alert, Snackbar } from "@mui/material";
import LoadingSpinner from "../shared/loading.spinner";

const DEFAULT_FORM_FIELDS = {
    asset_serial: "",
    make: "",
    model: "",
    asset_type: "",
    comment: "",
    customer_id: "",
    incident_date: "",
    site_contact_first_name: "",
    site_contact_last_name: "",
    site_contact_number: "",
    site_contact_secondary_number: "",
    site_contact_email: "",
    problem_type: "Broken Connector",
    parts_needed: "182613",
    parts_ordered: "182615",
    parts_shipping_carrier: "USPS",
    parts_tracking_number: "",
    case_number: '',
    claimAttachments: [],
};

const DEFAULT_PARTS_FIELDS = {
    description: "",
    sku: "",
    cost: "",
    quantity: "",
    total_cost: "",
    provider: "",
};

const DEFAULT_PHYSICAL_ADDRESS = {
    "Charger Location Address Line 1": '',
    "Charger Location Address Line 2": '',
    "Charger Location City": '',
    "Charger Location State": '',
    "Charger Location Postal Code": '',
}


const SubmitClaimsContent = () => {
    //UI Related Variables
    const perils = ['Broken Connector', 'Blank or non-responsive screen', 'Error message on screen', 'Connection error', 'Payment system failure', 'Charger initiation failure', 'Other']
    const shippingCarriers = ['USPS', 'FedEx', 'UPS', 'Other'];
    const [claimSubmitted, setClaimSubmitted] = useState(false);
    const addressStyleClass = 'flex flex-col gap-1 w-full md:w-1/2 text-gray-500';
    const btnAdd = 'bg-evstarLighGreen rounded-lg content-center text-center text-sm text-white hover:bg-white hover:text-evstarLighGreen border-evstarLighGreen border-[1px] cursor-pointer duration-300 ease-in-out transform';
    const txtFieldGreen = 'h-8 w-full  bg-evstarLighGreen text-white rounded-md';
    //Variables For Claims Submission
    const { getAssets } = useAsset();
    const [sitePhysicalAddress, setSitePhysicalAddress] = useState(DEFAULT_PHYSICAL_ADDRESS);
    const [formFields, setFormFields] = useState(DEFAULT_FORM_FIELDS);
    const [partsFormFields, setPartsFormFields] = useState(DEFAULT_PARTS_FIELDS);
    const [assetOptions, setAssetOptions] = useState([]);
    const [parts, setParts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [partsWarning, setPartsWarning] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState({});
    const { parts_tracking_number, parts_shipping_carrier, parts_ordered, parts_needed,
        problem_type, incident_date, asset_serial, make,
        model, asset_type, comment, customer_id,
        site_contact_email, site_contact_first_name, site_contact_last_name,
        site_contact_number, site_contact_secondary_number, case_number } = formFields;
    const { cost, description, provider, quantity, sku, total_cost } = partsFormFields;
    const [createTicket] = useMutation(CREATE_TICKET_MUTATION);
    const { user } = useContext(UserContext);
    const { customer } = useContext(CustomerContext);
    const errorMessage = useRef('');
    let searchTimer;


    //Functions For Claims Submission
    const handleSearchAssets = (e) => {
        const value = e.target.value;
        setFormFields({ ...formFields, asset_serial: value });
        if (value?.length < 3) {
            if (assetOptions?.length > 0) setAssetOptions([]);
            return;
        }
        // Clear the previous timer if it exists
        if (searchTimer) {
            clearTimeout(searchTimer);
        }
        // Set a new timer to delay the search
        searchTimer = setTimeout(async () => {
            console.log(formFields, formFields.customer_id);
            const assetInput = { warranty: true, customer_id: user?.role === "codi admin" ? parseInt(customer_id) : user?.customer_id }

            const res = await getAssets({
                searchParams: `search=${value}&limit=5`,
                AssetInput: assetInput
            });
            setAssetOptions(res);
        }, 700); // 0.7-second delay
    };

    const handleSelectedAsset = (e) => {
        const value = e.target.textContent;
        const selectedAsset = assetOptions.find((asset) => {
            return asset.asset_serial === value;
        });
        if (!selectedAsset) return alert("Asset not found");
        setSelectedAsset(selectedAsset);
        setFormFields({
            ...formFields,
            asset_serial: selectedAsset.asset_serial,
            make: selectedAsset?.make || "",
            model: selectedAsset?.model || "",
            asset_type: selectedAsset.asset_type,
        });
        setSitePhysicalAddress({ 
            "Charger Location Address Line 1": selectedAsset.properties["Charger Location Address Line 1"], 
            "Charger Location Address Line 2": selectedAsset.properties["Charger Location Address Line 2"],
            "Charger Location City": selectedAsset.properties["Charger Location City"],
            "Charger Location State": selectedAsset.properties["Charger Location State"],
            "Charger Location Postal Code": selectedAsset.properties["Charger Location Postal Code"],
        });
        setAssetOptions([]);
    };


    const handleChange = async (e) => {
        if (e.target.name === "claimAttachments") {
            const files = Array.from(e.target.files);
    
            // Convert the new files to base64 and add them to the existing files
            const processedFiles = await Promise.all(
                files.map(async (file) => {
                    const base64String = await convertToBase64(file);
                    return {
                        type: file.type,
                        name: file.name,
                        base64String: base64String,
                    };
                })
            );
    
            // Append the new files to the existing ones
            setFormFields((prevFormFields) => ({
                ...prevFormFields,
                claimAttachments: [
                    ...(prevFormFields.claimAttachments || []),
                    ...processedFiles,
                ],
            }));
        } else {
            // Handle other form fields
            setFormFields((prevFormFields) => ({
                ...prevFormFields,
                [e.target.name]: e.target.value,
            }));
        }
    };
    

    const onChange = (e) => {
        setPartsFormFields({ ...partsFormFields, [e.target.name]: e.target.value });
    }

    useEffect(()=>{
        console.log('Form Fields:', formFields);        
    }, [formFields]);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file); // Read the file as a base64 encoded string
            reader.onload = () => resolve(reader.result); // Resolve with the base64 string
            reader.onerror = (error) => reject(error); // Reject if an error occurs
        });
    };


    const getCurrentDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleAddPart = () => {
        if(parts.length>=4){
            errorMessage.current='Sorry, you can add four parts only';
            setShowError(true);
            return;
        }
        setParts([...parts, partsFormFields]);
        setPartsFormFields(DEFAULT_PARTS_FIELDS);
    }

    const handleRemovePart = (index) => {
        let previous = [];
        parts.forEach((element, i) => {
            if (i === index) { }
            else {
                previous.push(element);
            }
        });
        setParts(previous);
    }

    useEffect(() => {
        console.log('Parts:', parts);
    }, [parts]);

    const removeAttachment = (index) => {
        setFormFields((prevFormFields) => {
            const updatedAttachments = [...prevFormFields.claimAttachments];
            updatedAttachments.splice(index, 1); // Remove the attachment at the specified index
            
            return {
                ...prevFormFields,
                claimAttachments: updatedAttachments, // Update the claimAttachments field
            };
        });
    };
    

    const handleSubmit = () => {
        const emptyFields = Object.keys(formFields).filter(key => formFields[key] === "" && key !== "customer_id" && key !== 'parts_tracking_number' && key !== 'case_number');

        if (emptyFields.length > 0) {
            console.log("The following fields need to be filled:", emptyFields.join(", "));
            errorMessage.current = 'Please fill all the required fields';
            setShowError(true);
        } else {
            // Add your form submission logic here        
            console.log('###  Parts:', parts);
            console.log('###  Cusomter:', customer);
            console.log('###  User Input:', formFields);

            // const files = formFields.claimAttachments;
            // files.forEach((f)=>{
            //     console.log();
            // });

            try {
                setIsLoading(true);
                createTicket({
                    variables: {
                        problem_type: problem_type,
                        comment: comment,
                        asset_id: selectedAsset?.id,
                        contact_id: user.id,
                        asset_serial: asset_serial,
                        make: make,
                        model: model,
                        asset_type: asset_type,
                        customer_id: null,
                        data: {
                            parts: parts,
                            inputs: {...formFields, siteAddress:sitePhysicalAddress},
                            customer: customer,
                            user: user,
                            files:formFields.claimAttachments,
                        }
                    },
                    onCompleted: (res) => {
                        setIsLoading(false);
                        // console.log('OnComplete Called');
                        alert(`Claim submitted. Ticket #${res.createTicket.number}`);
                        // setSelectedAsset({});
                        // setAssetOptions([]);
                        // setFormFields({
                        //   asset_serial: "",
                        //   make: "",
                        //   model: "",
                        //   asset_type: "",
                        //   comment: "",
                        // });
                    },
                    onError: (err) => {
                        setIsLoading(false);
                        console.log('On Error', err.message);
                    }
                });
            } catch (error) {
                console.log(error);
                setIsLoading(false);
                error?.message ? alert(error.message) : alert("Contact system admin.");
            }
        }
    }



    return (
        <>
            {!claimSubmitted && (
                <>
                    <Heading text={'Submit Claims'} />
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={showError} autoHideDuration={6000} onClose={() => setShowError(false)}>
                        <Alert
                            onClose={() => { 
                                setShowError(false);
                                errorMessage.current='';
                             }}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {errorMessage.current}
                        </Alert>
                    </Snackbar>

                    <div className="border-2 border-solid p-4 rounded-2xl flex flex-col gap-3">
                        <div className="flex flex-col gap-1 relative">
                            <div className="font-bold text-md">Device Serial Number <span className="text-red-700">*</span></div>
                            <div className="text-sm">Begin typing to auto-fill device infomration</div>
                            <input
                                list="asset_serial_options"
                                id="asset_serial"
                                autoComplete="off"
                                type="text"
                                name="asset_serial"
                                value={asset_serial}
                                onChange={handleSearchAssets}
                                className="bg-evstarLandingInputField rounded-lg p-2 text-base font-interMedium h-8"
                            />
                            {assetOptions && (
                                <ul className="absolute top-[90px] left-0 mt-2 bg-white shadow-md">
                                    {assetOptions.map((asset) => (
                                        <li
                                            key={asset._id}
                                            value={asset.asset_serial}
                                            className="p-2 cursor-pointer hover:bg-gray-100"
                                            onClick={handleSelectedAsset}
                                        >
                                            {asset?.asset_serial}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {/* <AutoSuggestInput list={serialNumbers} onChange={onChange} inputStyle={{ height: '2rem', width: '100%', backgroundColor: '#DFE3E8', borderRadius: '0.5rem' }} /> */}
                        </div>
                        <div className="flex flex-col md:flex-row w-full gap-2">
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">EV Charger Make: <span className="text-red-700">*</span></div>
                                <input
                                    type="text"
                                    name="make"
                                    value={make}
                                    readOnly
                                    onChange={handleChange}
                                    className="h-8 w-full  bg-evstarLandingInputField rounded-lg"
                                />
                                {/* <input id="chargerMakeText" readOnly value={chargerMake} type="text" className="h-8 w-full  bg-evstarLandingInputField rounded-lg" /> */}
                            </div>
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">EV Charger Model: <span className="text-red-700">*</span></div>
                                <input
                                    type="text"
                                    name="model"
                                    value={model}
                                    onChange={handleChange}
                                    className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                                    readOnly
                                />
                                {/* <input id="chargerModelText" readOnly value={chargerModel} type="text" className="h-8 w-full bg-evstarLandingInputField rounded-lg" /> */}
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row w-full gap-2">
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">Case Number</div>
                                <div className="text-sm">Optional for internal tracking purposes</div>
                                <input
                                    type="text"
                                    name="case_number"
                                    value={case_number}
                                    onChange={handleChange}
                                    className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                                />
                                {/* <input id="chargerModelText" readOnly value={chargerModel} type="text" className="h-8 w-full bg-evstarLandingInputField rounded-lg" /> */}
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row w-full gap-2">
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">Date of Incident: <span className="text-red-700">*</span></div>
                                <input
                                    type="date"
                                    id="incident_date"
                                    name="incident_date"
                                    value={incident_date}
                                    onChange={handleChange}
                                    max={getCurrentDate()}
                                    className="h-10 w-full rounded-lg"
                                />
                            </div>
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">Select one: <span className="text-red-700">*</span></div>
                                <select
                                    name="problem_type"
                                    value={problem_type}
                                    onChange={handleChange}
                                    className="w-full rounded-lg"
                                >
                                    {perils.map((p) => <option key={p} value={p}>{p}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row w-full gap-2">
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">Are parts needed?: <span className="text-red-700">*</span></div>
                                <select
                                    name="parts_needed"
                                    value={parts_needed}
                                    onChange={handleChange}
                                    className="w-full rounded-lg">
                                    {/* {partsNeeded.map((val) => <option key={val} value={val}>{val}</option>)} */}
                                    <option value="182613">No</option>
                                    <option value="182612">Yes</option>
                                    <option value="184395">Unknown</option>
                                </select>
                            </div>
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">Have parts been ordered?: <span className="text-red-700">*</span></div>
                                <select
                                    name="parts_ordered"
                                    value={parts_ordered}
                                    onChange={handleChange}
                                    className="w-full rounded-lg">
                                    {/* {partsOrdered.map((val) => <option key={val} value={val}>{val}</option>)} */}
                                    <option value="182615">No</option>
                                    <option value="182614">Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <div className="font-bold text-md"> If parts order has been placed, enter the details below.</div>
                            <div className="flex flex-col  lg:flex-row w-full">
                                <div className="flex flex-row items-end gap-1">
                                    <div className="flex flex-col">
                                        <div>Part Description</div>
                                        <div>
                                            <input
                                                type="text"
                                                name="description"
                                                value={description}
                                                onChange={onChange}
                                                className={`${txtFieldGreen}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-[40%] flex flex-col">
                                        <div>Part SKU</div>
                                        <div>
                                            <input
                                                type="text"
                                                name="sku"
                                                value={sku}
                                                onChange={onChange}
                                                className={`${txtFieldGreen}`}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="w-[20%] flex flex-col">
                                        <div>Part Cost</div>
                                        <div>
                                            <input
                                                type="text"
                                                name="cost"
                                                value={cost}
                                                onChange={onChange}
                                                className={`${txtFieldGreen}`}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="w-[20%] flex flex-col">
                                        <div>Part Qty</div>
                                        <div>
                                            <input
                                                type="text"
                                                name="quantity"
                                                value={quantity}
                                                onChange={onChange}
                                                className={`${txtFieldGreen}`}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="flex flex-row w-full items-end gap-1 pl-1">
                                    <div className="flex flex-col">
                                        <div>Part Total Cost</div>
                                        <div>
                                            <input
                                                type="text"
                                                name="total_cost"
                                                value={total_cost}
                                                onChange={onChange}
                                                className={`${txtFieldGreen}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div>Parts Provider</div>
                                        <div>
                                            <input
                                                type="text"
                                                name="provider"
                                                value={provider}
                                                onChange={onChange}
                                                className={`${txtFieldGreen}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-1/3 flex flex-col">
                                        <div></div>
                                        <div className={`${btnAdd} h-8 `} onClick={handleAddPart}>
                                            Add
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-evstarLighGreen border-[1px] w-full overflow-x-auto flex flex-col gap-1 rounded-lg md:px-2 py-1">
                                <div className="flex flex-row text-sm md:text-base justify-center w-full">
                                    <div className="w-[20%] font-bold ">Part Description</div>
                                    <div className="w-[20%] font-bold ">Part SKU</div>
                                    {/* <div className="w-[20%] font-bold ">Part Cost</div> */}
                                    {/* <div className="w-[20%] font-bold ">Part Qty</div> */}
                                    <div className="w-[20%] font-bold ">Part Total Cost</div>
                                    <div className="w-[20%] font-bold ">Parts Provider</div>
                                    <div className="w-[20%]"></div>
                                </div>

                                {parts.map((p, i) => {
                                    return (
                                        <>
                                            <div key={i} className="flex flex-row text-sm justify-center w-[140%] lg:w-full">
                                                <div className="w-[20%] ">{p.description}</div>
                                                <div className="w-[20%] ">{p.sku}</div>
                                                {/* <div className="w-[20%] ">${p.cost}</div> */}
                                                {/* <div className="w-[20%] ">{p.quantity}</div> */}
                                                <div className="w-[20%] ">${p.total_cost}</div>
                                                <div className="w-[20%] ">{p.provider}</div>
                                                <div className="w-[20%]">
                                                    <div className={`${btnAdd} h-6`} onClick={() => {
                                                        handleRemovePart(i);
                                                    }}>
                                                        Remove
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-[140%] lg:w-full"><hr /></div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row w-full gap-2">
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">Part Ordered Shipping Carrier: <span className="text-red-700">*</span></div>
                                <select
                                    name="parts_shipping_carrier"
                                    value={parts_shipping_carrier}
                                    onChange={handleChange}
                                    className="w-full rounded-lg">
                                    {shippingCarriers.map((val) => <option key={val} value={val}>{val}</option>)}
                                </select>
                            </div>
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="flex flex-col gap-1 w-full">
                                    <div className="font-bold text-md"> Part Ordered Tracking Number:</div>
                                    <input
                                        type="text"
                                        name="parts_tracking_number"
                                        value={parts_tracking_number}
                                        onChange={handleChange}
                                        className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row w-full gap-2">
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">Site Contact First Name: <span className="text-red-700">*</span></div>
                                <input
                                    type="text"
                                    name="site_contact_first_name"
                                    value={site_contact_first_name}
                                    onChange={handleChange}
                                    className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                                />
                            </div>
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md"> Site Contact Last Name: <span className="text-red-700">*</span></div>
                                <input
                                    type="text"
                                    name="site_contact_last_name"
                                    value={site_contact_last_name}
                                    onChange={handleChange}
                                    className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row w-full gap-2">
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">Site Contact Phone #: <span className="text-red-700">*</span></div>
                                <input
                                    type="text"
                                    name="site_contact_number"
                                    value={site_contact_number}
                                    onChange={handleChange}
                                    className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                                />
                            </div>
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md"> Secondary Contact Phone #: <span className="text-red-700">*</span></div>
                                <input
                                    type="text"
                                    name="site_contact_secondary_number"
                                    value={site_contact_secondary_number}
                                    onChange={handleChange}
                                    className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row w-full gap-2">
                            <div className="flex flex-col gap-1 w-full md:w-1/2">
                                <div className="font-bold text-md">Site Contact Email Address: <span className="text-red-700">*</span></div>
                                <input
                                    type="text"
                                    name="site_contact_email"
                                    value={site_contact_email}
                                    onChange={handleChange}
                                    className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 border-black border-[1px] p-2 rounded-lg">
                            <div className="font-bold text-md">Site Physical Address: </div>
                            <div>
                                Please confirm the address below is correct. If this is incorrect call EVStar at 855-838-7827
                            </div>
                            <div className="flex flex-col md:flex-row">
                                <div className="flex flex-col w-full gap-2">
                                    <div className={`${addressStyleClass}`}>
                                        {sitePhysicalAddress["Charger Location Address Line 1"]}
                                    </div>
                                    <div className={`${addressStyleClass}`}>
                                        {sitePhysicalAddress["Charger Location Address Line 2"]}
                                    </div>
                                </div>
                                <div className="flex flex-col w-full gap-2">
                                    <div className={`${addressStyleClass}`}>
                                        {sitePhysicalAddress["Charger Location City"]}
                                    </div>
                                    <div className={`${addressStyleClass}`}>
                                        {sitePhysicalAddress["Charger Location State"]}
                                    </div>
                                    <div className={`${addressStyleClass}`}>
                                        {sitePhysicalAddress["Charger Location Postal Code"]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                        <div className="font-bold text-md">Upload Attachments</div>
                            <label
                                htmlFor="claimAttachments"
                                className="w-full lg:text-sm text-base lg:w-1/5 md:w-1/4 text-center cursor-pointer bg-evstarLighGreen text-white px-4 py-2 rounded-lg hover:bg-white hover:text-evstarLighGreen hover:border-evstarLighGreen border-[1px] transition-colors duration-300"
                            >
                                Add Attachments
                            </label>
                            <input
                                type="file"
                                name="claimAttachments"
                                id="claimAttachments"
                                className="hidden"
                                accept=".jpg,.jpeg,.png,.pdf"
                                onChange={handleChange}
                                multiple
                                />
                            {
                                formFields.claimAttachments.map((f,i)=>
                                    <div className="flex gap-3">
                                        <div className={'text-[#4E95D9] font-bold'}>
                                            {f.name}
                                        </div>
                                        <div className={'text-red-600 cursor-pointer'} onClick={()=>{
                                            removeAttachment(i);
                                        }}>
                                            x
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="font-bold text-md">Describe what happened: <span className="text-red-700">*</span></div>
                            <textarea type="text" rows={4} className="w-full bg-evstarLandingInputField rounded-lg" name="comment" value={comment} onChange={handleChange} />
                        </div>
                        <div className="flex flex-row justify-center">
                            <div className="w-max">
                                {isLoading
                                    ? <LoadingSpinner />
                                    : <OutLinedButton text={'Submit Claim'} onClick={handleSubmit} />}
                            </div>
                        </div>
                    </div>
                </>
            )
            }
            {claimSubmitted && <div className="border-2 border-solid p-4 rounded-2xl flex flex-col gap-3">
                <div className="text-center">Claim {`1234`} has been created </div>
                <div className="text-center">Thank you for filing your claim. We will be in touch within 24 hours regarding the next steps.</div>
                <div className="flex flex-row justify-center">
                    <div className="w-max">
                        <OutLinedButton text={'Return To Home'} onClick={() => {
                            setClaimSubmitted(false);
                        }} />
                    </div>
                </div>
            </div>}
        </>
    );
}
export default SubmitClaimsContent;