function convertToMMDDYYYY(dateString) {
    // Parse the input date string into a JavaScript Date object
    const date = new Date(dateString);

    // Extract month, day, and year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is 0-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    // Format as mm/dd/yyyy
    return `${month}/${day}/${year}`;
}

export default convertToMMDDYYYY;