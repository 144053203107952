import DefaultLayout from "../layouts/default.layout";
import ContactsTableCard from "../components/cards/contactsTable.card";
import { UserContext } from "../contexts/user.context";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header1 from "../components/header.component1";
import { useContext, useEffect, useState } from "react";
import LoggedIn1 from "../layouts/loggedIn.layout1";
import SearchOptions from "../components/searchOptions.component";
import MenuBarMobile from "../components/sidebar/menubar.mobile";
import Sidebar from "../components/sidebar/sidebar.component";
import EvStarPagination from "../components/pagination1.component";
import useContact from "../hooks/useContact";
import { ManageUsersContent } from "../components/content/manage.users.content";


const DEFAULT_SEARCH_OPTIONS = {
    search: "",
    sortBy: "number",
    sortDirection: "asc",
    statusFilter: [],
    page: 1,
    maxPages: 10,
};



const ManageContacts1 = () => {
    const [searchOptions, setSearchOptions] = useState(DEFAULT_SEARCH_OPTIONS);
    const [showSidebar, setShowSidebar] = useState(false);
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(+searchParams?.get("page") || 1);
    const { user } = useContext(UserContext);
    const [totalRecords, setTotalRecords] = useState();
    const { fetchContacts, fetchContactCount } = useContact();
    const [contacts, setContacts] = useState([]);
    const navigate = useNavigate();
    let reload = false;

    let { customerId } = useParams();
    customerId = parseInt(customerId);


    useEffect(() => {
        if (!user?.id) return;
        if (!searchParams.toString()) return;
    
        const fetchAndSetContacts = async () => {
          const fetchedContacts = await fetchContacts({
            customer_id: parseInt(customerId),
            searchParams: searchParams.toString(),
          });
          setContacts(fetchedContacts);
          //console.log(fetchedContacts);
        };
    
        const fetchContactsTotal = async () => {
          const fetchedContactsCount = await fetchContactCount({
            customer_id: parseInt(customerId),
            searchParams: searchParams.toString(),
          });
          setTotalRecords(fetchedContactsCount);
          console.log(fetchedContactsCount);
        };
    
        fetchAndSetContacts();
        fetchContactsTotal();
      }, [searchParams, user, reload]);

      const navigateToContactSave = () => {
        navigate(`/contact-save?customerId=${customerId}`);
      };


    return (
        <LoggedIn1>
            <div className="bg-white flex flex-col items-center min-h-screen">
                <div className="lg:w-5/6 w-full">
                    <Header1 showWhatsCovered />
                    <div className="hidden">
                        <SearchOptions
                            type="contacts"
                            searchOptionsState={[searchOptions, setSearchOptions]}
                        />
                    </div>
                    <div className="h-full ">
                        <div className="flex h-full ">
                            <MenuBarMobile setter={setShowSidebar} />
                            <Sidebar show={showSidebar} setter={setShowSidebar} />
                            <div className="flex flex-col w-full min-h-screen p-4 gap-4">
                            <div className='new-user-btn-container'>
                                <button onClick={navigateToContactSave} className='uppercase border font-interSemiBold border-evstarLighGreen hover:bg-white bg-evstarLighGreen text-white hover:text-evstarLighGreen py-2 px-8 rounded-lg transition duration-300' >{user?.role === "codi admin" && customerId === user?.customer_id? "Add New Codi Admin" : "Add New User" }</button>
                            </div>
                                <ManageUsersContent contacts={contacts} customerId={customerId}/>
                                <EvStarPagination currentPage={page} maxPages={Math.ceil(totalRecords/10)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoggedIn1>
    );
};

export default ManageContacts1;
