import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useSearchParams } from 'react-router-dom';
import getPaginationPages from '../utils/getPaginationPages.utils';

const PAGE_SIZE = 10;

export default function EvStarPagination({
    count = 1,
    maxPages = 1,
}) {
    const [page, setPage] = React.useState(1);
    const handleChange = (event, page) => {
        setPage(page);
        otherPage(page)
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const [pages, setPages] = React.useState([]);
    const currentPage = +searchParams.get("page") || 1;
    const pageCount = Math.ceil(count / PAGE_SIZE);
    React.useEffect(() => {
        if (!(maxPages > 1)) return setPages([1]);
        setPages(getPaginationPages(maxPages, currentPage));
    }, [maxPages, currentPage]);

    const nextPage = () => {
        const next = currentPage === maxPages ? 1 : currentPage + 1;
        searchParams.set("page", next);
        setSearchParams(searchParams);
    };
    const prevPage = () => {
        const prev = currentPage === 1 ? maxPages : currentPage - 1;
        searchParams.set("page", prev);
        setSearchParams(searchParams);
    };

    const otherPage = (page) => {
        searchParams.set("page", page);
        setSearchParams(searchParams);
    };

    return (
        <Stack>
            <Pagination count={maxPages} page={page} onChange={handleChange} color='standard' />
        </Stack>
    );
}
