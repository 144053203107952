import '@llamaindex/pdf-viewer/index.css';
import { PDFViewer, PdfFocusProvider } from '@llamaindex/pdf-viewer';

const PDFViewer1 = ({url}) => {
    return (
        <PdfFocusProvider>
            <PDFViewer file={{id:'', url:url}} hideOptionBar />
        </PdfFocusProvider>
    );
}
export default PDFViewer1;